{
  "name": "app",
  "version": "1.0.0",
  "description": "app",
  "author": {
    "name": "Dener Rocha",
    "email": "denernun@gmail.com"
  },
  "repository": {
    "type": "git",
    "url": "https://github.com/softclass/erpclass-shop.git"
  },
  "bugs": {
    "url": "https://github.com/softclass/erpclass-shop/issues"
  },
  "private": true,
  "license": "UNLICENSED",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --ssl --ssl-cert=./certs/localhost.crt --ssl-key=./certs/localhost.key --configuration=development",
    "build": "ng build --configuration=production",
    "lint": "eslint --color -c .eslintrc.json --ext .ts,.html ./src/",
    "lint-fix": "eslint --color --fix -c .eslintrc.json --fix --ext .ts,.html ./src/"
  },
  "dependencies": {
    "@angular/animations": "^19.2.2",
    "@angular/common": "^19.2.2",
    "@angular/compiler": "^19.2.2",
    "@angular/core": "^19.2.2",
    "@angular/forms": "^19.2.2",
    "@angular/platform-browser": "^19.2.2",
    "@angular/platform-browser-dynamic": "^19.2.2",
    "@angular/router": "^19.2.2",
    "@auth0/angular-jwt": "^5.2.0",
    "@fnando/cnpj": "^2.0.0",
    "@fnando/cpf": "^1.0.2",
    "@fortawesome/fontawesome-free": "^6.7.2",
    "@ng-select/ng-select": "^14.2.3",
    "@ngrx/effects": "^19.0.1",
    "@ngrx/store": "^19.0.1",
    "@ngrx/store-devtools": "^19.0.1",
    "@ngspot/ngx-errors": "^3.2.3",
    "@rxweb/reactive-form-validators": "^13.0.1",
    "accounting": "^0.4.1",
    "bootstrap": "^5.3.3",
    "bootstrap-icons": "^1.11.3",
    "core-js": "~3.41.0",
    "dayjs": "^1.11.13",
    "decimal.js": "^10.5.0",
    "file-saver": "^2.0.5",
    "js-base64": "^3.7.7",
    "lodash": "^4.17.21",
    "ngx-bootstrap": "^19.0.2",
    "ngx-currency": "^19.0.0",
    "ngx-device-detector": "^9.0.0",
    "ngx-loading": "^17.0.0",
    "ngx-mask": "^19.0.6",
    "ngx-pagination": "^6.0.3",
    "ngx-skeleton-loader": "^10.0.0",
    "ngx-toastr": "^19.0.0",
    "ngx-webstorage": "^19.0.1",
    "rxjs": "~7.8.2",
    "socket.io-client": "^4.8.1",
    "sweetalert2": "^11.17.2",
    "ts-debug": "^1.3.0",
    "tslib": "^2.8.1",
    "uuid": "^11.1.0",
    "validator": "^13.12.0",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.3",
    "@angular-eslint/builder": "^19.2.1",
    "@angular-eslint/eslint-plugin": "^19.2.1",
    "@angular-eslint/eslint-plugin-template": "^19.2.1",
    "@angular-eslint/schematics": "^19.2.1",
    "@angular-eslint/template-parser": "^19.2.1",
    "@angular/cli": "^19.2.3",
    "@angular/compiler-cli": "^19.2.2",
    "@angular/language-service": "^19.2.2",
    "@rxweb/types": "^1.0.8",
    "@types/accounting": "^0.4.5",
    "@types/core-js": "~2.5.8",
    "@types/eslint": "^9.6.1",
    "@types/eslint-plugin-prettier": "^3.1.3",
    "@types/lodash": "^4.17.16",
    "@types/node": "^22.13.10",
    "@types/prettier": "^3.0.0",
    "@types/uuid": "^10.0.0",
    "@types/validator": "^13.12.2",
    "@typescript-eslint/eslint-plugin": "^8.26.1",
    "@typescript-eslint/parser": "^8.26.1",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^10.1.1",
    "eslint-plugin-prettier": "^5.2.3",
    "prettier": "^3.5.3",
    "prettier-eslint": "^16.3.0",
    "replace-in-file": "^8.3.0",
    "ts-node": "^10.9.2",
    "typescript": "^5.6.3"
  }
}
